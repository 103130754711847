<template>
  <v-card>
    <v-card-subtitle class="mb-n8" v-if="authorizer_name !== null"
      >{{ question.isActive() && hasVoted === false ? 'Glasujete u ime:' : '' }}
      <span class="text-overline">{{ authorizer_name }}</span>
      <v-icon v-if="hasVoted === true" color="success"> mdi-check </v-icon>
      <v-icon v-if="question.isFinished() && hasVoted === false" color="red">
        mdi-close
      </v-icon>
    </v-card-subtitle>
    <v-card-text>
      <div class="question-title">
        <p>{{ question.question }}</p>
      </div>
      <div
        v-if="
          hasVoted === true &&
          $mystore.state.event !== null &&
          $mystore.state.event.results_in_progress === false &&
          question.isActive()
        "
      >
        <p>
          <span style="white-space: pre-wrap">{{
            $t('views.question.resultsNotVisibleDuringEventActive')
          }}</span>
        </p>
      </div>

      <div
        v-if="
          question.isFinished() ||
          ($mystore.state.event !== null &&
            $mystore.state.event.results_in_progress === true &&
            hasVoted === true &&
            Object.values(votes).length > 0)
        "
      >
        <p v-if="Object.keys(votes).length > 0">
          <span style="white-space: pre-wrap"
            ><b>{{ $t('views.question.theseAreEventResults') }}</b></span
          >
        </p>

        <p v-else>
          <b>{{ $t('views.question.noResults') }}</b>
        </p>

        <div v-for="answer in question.answers" :key="answer.id">
          <p class="break-all">
            {{ answer.label }}
            <b v-if="givenVotes.filter(vote => vote === answer.id).length > 0"
              >({{ $t('views.question.yourVote') }})</b
            >
          </p>

          <v-progress-linear
            :value="
              votes[answer.id] !== undefined ? votes[answer.id].percentage : 0
            "
            height="25"
          >
            <template v-slot="{ value }">
              <strong v-if="votes[answer.id] !== undefined"
                >({{ votes[answer.id].votes }}) {{ value }}%</strong
              >
              <strong v-if="votes[answer.id] == undefined">(0) 0%</strong>
            </template>
          </v-progress-linear>

          <br />
        </div>

        <div v-if="question.abstention === true">
          <p>
            {{ $t('views.question.abstentioned') }}

            <b v-if="givenVotes.filter(vote => vote === null).length > 0"
              >({{ $t('views.question.yourVote') }})</b
            >
          </p>

          <v-progress-linear
            :value="
              votes['abstention'] !== undefined
                ? votes['abstention'].percentage
                : 0
            "
            height="25"
          >
            <template v-slot="{ value }">
              <strong v-if="votes['abstention'] !== undefined"
                >({{ votes['abstention'].votes }}) {{ value }}%</strong
              >
              <strong v-if="votes['abstention'] == undefined">(0) 0%</strong>
            </template>
          </v-progress-linear>

          <br />
        </div>
      </div>
      <p v-else-if="question.isActive() && hasVoted === 'pending'">
        {{ $t('views.question.thankYouForVoting') }}
      </p>

      <v-form
        v-if="hasVoted === false && question.isActive()"
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-radio-group
          v-if="question.multiple_choices_enabled === false"
          v-model="selected_answer"
          :disabled="abstention"
          :rules="selected_answer_rules"
        >
          <v-radio
            v-for="answer in answers"
            :key="answer.id"
            :label="answer.label"
            :value="answer.id"
          ></v-radio>
        </v-radio-group>

        <v-checkbox
          v-else
          v-for="answer in answers"
          v-model="selected_answer"
          :key="answer.id"
          :label="answer.label"
          :value="answer.id"
          :disabled="abstention"
          :rules="selected_answer_rules"
        ></v-checkbox>

        <v-checkbox
          v-if="question.abstention === true"
          :label="$t('views.question.abstention')"
          :value="true"
          v-model="abstention"
          v-on:change="toggleAbstention"
        ></v-checkbox>

        <div
          style="white-space: pre-wrap"
          v-if="
            question.anonymous == true ||
            (question.anonymous == null &&
              $mystore.state.event.anonymous == true)
          "
        >
          {{ $t('components.layouts.main.anonymous') }}
        </div>
        <div v-else style="white-space: pre-wrap">
          {{ $t('components.layouts.main.transparent') }}
        </div>

        <v-btn
          :disabled="!valid"
          color="success"
          class="mr-4 mt-5"
          @click.stop="formSubmit"
        >
          {{ $t('views.question.send') }}
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import Question from '@/models/Question.js'
import handle_errors from '@/api/handle_errors.js'
import catch_errors from '@/api/catch_errors.js'
import handle_token_refresh from '@/api/handle_token_refresh.js'

async function submit_score(question_id, payload, token) {
  return await fetch(
    `${process.env.VUE_APP_API_URL}/voter/questions/${question_id}/votes`,
    {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    }
  )
}

/**
 * Implements Fisher-Yates shuffle algorithm.
 *
 * Taken from: https://attacomsian.com/blog/javascript-shuffle-array
 */
function shuffle(array_in) {
  let array_out = array_in.slice()

  // loop all elements
  for (let i = array_out.length - 1; i > 0; i--) {
    // pickup a random element
    const j = Math.floor(Math.random() * i)
    const temp = array_out[i]

    // swap it with the current element
    array_out[i] = array_out[j]
    array_out[j] = temp
  }

  return array_out
}

const getValueAsArray = v => (Array.isArray(v) ? v : [v])

export default {
  name: 'Ticket',

  props: {
    question: Question,
    authorizer: [Object, null],
    voted: [Boolean, Array, String],
    votes: [Array, Object],
    results: Array,
  },

  data() {
    return {
      authorizer_name:
        this.authorizer === undefined ? null : this.authorizer.name,
      valid: true,
      selected_answer: [],
      abstention: false,
      refresh_interval: null,
    }
  },

  watch: {
    'question.stopped_at': function (value) {
      if (value !== null) {
        this.stopRefreshQuestionInterval()
      }
    },

    hasVoted: function (value) {
      this.valid = true
      this.selected_answer = []
      this.abstention = false

      if (value === false) {
        return
      }

      // Start the refreshQuestionInterval.
      this.startRefreshQuestionInterval()
    },
  },

  destroyed: function () {
    this.stopRefreshQuestionInterval()
  },

  computed: {
    givenVotes() {
      const [first] = this.results.filter(
        result => result.authorizer_id === this.authorizerId
      )

      return first === undefined ? [] : first.selected_answers
    },

    authorizerId() {
      return this.authorizer === undefined ? null : this.authorizer.id
    },

    hasVoted() {
      if (typeof this.voted === 'string') {
        return this.voted
      }

      if (typeof this.voted === 'boolean') {
        return this.voted
      }

      const [first] = this.voted.filter(
        vote => vote.authorizer_id === this.authorizer.id
      )

      return first === undefined ? false : first.voted
    },

    // NOTE: If this is confusing for the voter,
    // this could be moved to the Question.vue view
    // in order to have all answers equally randomized.
    answers() {
      if (this.question.randomize_options === true) {
        return shuffle(this.question.answers)
      }

      return this.question.answers
    },

    selected_answer_rules() {
      const rules = []

      if (this.abstention) return rules

      if (this.question.multiple_choices_enabled === true) {
        let rule = v =>
          getValueAsArray(v).length >= this.question.min_choices ||
          this.$t('views.question.validation.min', {
            value: this.question.min_choices,
          })

        rules.push(rule)

        if (this.question.max_choices == 0) return rules

        rule = v =>
          getValueAsArray(v).length <= this.question.max_choices ||
          this.$t('views.question.validation.max', {
            value: this.question.max_choices,
          })

        rules.push(rule)
      } else {
        const rule = v =>
          getValueAsArray(v).length > 0 ||
          this.$t('views.question.validation.required')

        rules.push(rule)
      }

      return rules
    },
  },

  methods: {
    toggleAbstention(value) {
      if (value === null) return

      this.selected_answer = []
    },

    formSubmit() {
      if (!this.$refs.form.validate()) return

      const payload = {
        abstention: this.abstention === null ? false : this.abstention,
        selected_answer: Array.isArray(this.selected_answer)
          ? this.selected_answer
          : [this.selected_answer],
        authorizer_id: this.authorizerId,
      }

      submit_score(this.question.id, payload, localStorage.getItem('token'))
        .then(handle_token_refresh)
        .then(handle_errors)
        .then(() => {
          this.$emit('score-submitted', this.authorizerId)

          // Start the refreshQuestionInterval.
          this.startRefreshQuestionInterval()
        })
        .catch(response => {
          catch_errors(response, this.$router, this.$mystore)
        })
    },

    startRefreshQuestionInterval() {
      // Stops previous refresh interval if any.
      if (this.refresh_interval !== null) {
        this.stopRefreshQuestionInterval()
      }

      if (
        this.question.isActive() &&
        // The first part of this condition could be redundant after future changes. Keep for extra safety.
        this.$mystore.state.event !== null &&
        this.$mystore.state.event.results_in_progress === true &&
        // Redundant, but let's just keep it here for extra safety.
        (this.hasVoted === true || this.hasVoted === 'pending')
      ) {
        this.refresh_interval = setInterval(() => {
          this.$emit('refresh-question')
        }, 5000)
      }
    },

    stopRefreshQuestionInterval() {
      if (this.refresh_interval === null) {
        return
      }

      clearInterval(this.refresh_interval)

      this.refresh_interval = null
    },
  },
}
</script>

<style scoped>
::v-deep .v-input__slot label {
  word-break: break-all;
}

.question-title {
  color: rgb(16, 16, 16);
  font-size: 25px;
  white-space: pre-wrap;
}
</style>
