<template>
  <MainLayout :title="title" v-on:logout="$emit('logout')">
    <QuestionAuthorizers
      v-if="$mystore.state.voter.authorizers.length > 0"
      :question="question"
      :voted="voted"
      :votes="votes"
      :results="results"
      @refresh-question="handleRefreshQuestion"
      @score-submitted="handleScoreSubmitted"
    >
    </QuestionAuthorizers>
    <v-container fluid v-else>
      <v-row>
        <v-col cols="12">
          <Ticket
            :question="question"
            :voted="voted"
            :votes="votes"
            :results="results"
            @refresh-question="handleRefreshQuestion"
            @score-submitted="handleScoreSubmitted"
          >
          </Ticket>
        </v-col>
      </v-row>
    </v-container>
  </MainLayout>
</template>

<script>
import MainLayout from '@/components/layouts/Main.vue'
import QuestionAuthorizers from '@/components/QuestionAuthorizers.vue'
import Ticket from '@/components/Ticket.vue'
import Question from '@/models/Question.js'
import catch_errors from '@/api/catch_errors.js'
import getQuestion from '@/api/getQuestion.js'

export default {
  name: 'Question',

  // this.$route.params.id
  props: ['id'],

  components: {
    MainLayout,
    QuestionAuthorizers,
    Ticket,
  },

  data() {
    return {
      title: this.$t('views.question.title'),
      question: new Question(),
      voted: [],
      votes: {},
      // This is populated only if the event is transparent, and
      // (question is finished or event results in progress are enabled).
      results: [],
    }
  },

  beforeRouteEnter(to, from, next) {
    getQuestion(
      to.params.id,
      data => {
        next(vm => {
          vm.question = new Question(data.data.question)
          vm.voted = data.data.voted
          vm.votes = data.data.votes
          vm.results = data.data.results || []

          document.title = vm.question.question
        })
      },
      response => {
        next(vm => {
          if (response.status === 404) {
            vm.$router.push({ name: 'Questions' })
          } else {
            catch_errors(response, vm.$router, vm.$mystore)
          }
        })
      }
    )
  },

  // when route changes and this component is already rendered,
  // the logic will be slightly different.
  // When route param id changes, get new question from api.
  beforeRouteUpdate(to, from, next) {
    this.question = new Question()
    this.voted = []
    this.votes = {}
    this.results = []

    getQuestion(
      to.params.id,
      data => {
        this.question = new Question(data.data.question)
        this.voted = data.data.voted
        this.votes = data.data.votes
        this.results = data.data.results || []

        document.title = this.question.question

        next()
      },
      response => {
        next(false)

        if (response.status === 404) {
          this.$router.push({ name: 'Questions' })
        } else {
          catch_errors(response, this.$router, this.$mystore)
        }
      }
    )
  },

  watch: {
    '$mystore.state.pusherInstance': function (value) {
      if (value === null) return

      this.bindToPusherEvents()
    },
  },

  created: function () {
    this.bindToPusherEvents()
  },

  destroyed: function () {
    if (this.$mystore.state.pusherChannel_questions === null) return

    this.$mystore.state.pusherChannel_questions.unbind(
      'stop',
      this.redirectToHome
    )
  },

  mounted() {
    this.$nextTick(() => {
      this.title = this.$t('views.question.title')
    })
  },

  methods: {
    /**
     * When the user has voted and there are no authorizers or when all authorizers have voted,
     * this method displays a notification, enables page navigation and redirects to the home page.
     */
    votingComplete() {
      this.$mystore.notifyUser(this.$t('views.question.thankYouForVoting'))

      // The user can navigate to other pages.
      this.$mystore.removeActiveQuestion()

      this.redirectToHome()
    },

    haveAllAuthorizersVoted() {
      return (
        this.$mystore.state.voter.authorizers.length ===
        this.voted.filter(
          vote => vote.voted === true || vote.voted === 'pending'
        ).length
      )
    },

    handleScoreSubmitted(authorizerVotedId) {
      this.optimisticLoading(authorizerVotedId)

      this.refreshQuestion(() => {
        const authorizers = this.$mystore.state.voter.authorizers

        this.optimisticLoading(authorizerVotedId)

        // If there are no authorizers for the voter.
        if (authorizers.length === 0) {
          this.votingComplete()
        } else if (this.haveAllAuthorizersVoted(authorizers)) {
          this.votingComplete()
        }
      })
    },

    /**
     * Optimistic loading.
     *
     * This code marks that the voter/authorizer has voted regardless
     * what the server responds with, because of the queue worker processing speed.
     */
    optimisticLoading(authorizerVotedId) {
      if (authorizerVotedId === null) {
        this.voted = 'pending'
      } else {
        this.voted = this.voted.map(vote => {
          if (vote.authorizer_id === authorizerVotedId) {
            vote.voted = 'pending'
          }

          return vote
        })
      }
    },

    handleRefreshQuestion() {
      this.refreshQuestion()
    },

    bindToPusherEvents() {
      if (this.$mystore.state.pusherInstance === null) return

      this.$mystore.state.pusherChannel_questions.bind(
        'stop',
        this.redirectToHome
      )
    },

    redirectToHome() {
      this.$router.push({ name: 'Home' })
    },

    refreshQuestion(callback = null) {
      getQuestion(
        this.id,
        data => {
          this.question = new Question(data.data.question)
          this.voted = data.data.voted
          this.votes = data.data.votes
          this.results = data.data.results || []

          if (callback !== null) {
            callback()
          }
        },
        response => catch_errors(response, this.$router, this.$mystore)
      )
    },
  },
}
</script>
