<template>
  <v-container fluid>
    <v-row v-for="authorizer in authorizers" :key="authorizer.id">
      <v-col cols="12">
        <Ticket
          :authorizer="authorizer"
          :question="question"
          :voted="voted"
          :votes="votes"
          :results="results"
          @refresh-question="handleRefreshQuestion"
          @score-submitted="handleScoreSubmitted"
        >
        </Ticket>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Question from '@/models/Question.js'
import Ticket from '@/components/Ticket.vue'

export default {
  name: 'QuestionAuthorizers',

  components: {
    Ticket,
  },

  props: {
    question: Question,
    voted: Array,
    votes: [Array, Object],
    results: Array,
  },

  computed: {
    authorizers() {
      return Array.from(this.$mystore.state.voter.authorizers).sort((a, b) => {
        // Orders authorizers by name.
        return a['name'] < b['name'] ? -1 : a['name'] > b['name'] ? 1 : 0
      })
    },
  },

  methods: {
    handleRefreshQuestion() {
      this.$emit('refresh-question')
    },

    handleScoreSubmitted(authorizerVotedId) {
      this.$emit('score-submitted', authorizerVotedId)
    },
  },
}
</script>
