import { render, staticRenderFns } from "./Ticket.vue?vue&type=template&id=32d0c986&scoped=true&"
import script from "./Ticket.vue?vue&type=script&lang=js&"
export * from "./Ticket.vue?vue&type=script&lang=js&"
import style0 from "./Ticket.vue?vue&type=style&index=0&id=32d0c986&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32d0c986",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
installComponents(component, {VBtn,VCard,VCardSubtitle,VCardText,VCheckbox,VForm,VIcon,VProgressLinear,VRadio,VRadioGroup})
