var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[(_vm.authorizer_name !== null)?_c('v-card-subtitle',{staticClass:"mb-n8"},[_vm._v(_vm._s(_vm.question.isActive() && _vm.hasVoted === false ? 'Glasujete u ime:' : '')+" "),_c('span',{staticClass:"text-overline"},[_vm._v(_vm._s(_vm.authorizer_name))]),(_vm.hasVoted === true)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-check ")]):_vm._e(),(_vm.question.isFinished() && _vm.hasVoted === false)?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-close ")]):_vm._e()],1):_vm._e(),_c('v-card-text',[_c('div',{staticClass:"question-title"},[_c('p',[_vm._v(_vm._s(_vm.question.question))])]),(
        _vm.hasVoted === true &&
        _vm.$mystore.state.event !== null &&
        _vm.$mystore.state.event.results_in_progress === false &&
        _vm.question.isActive()
      )?_c('div',[_c('p',[_c('span',{staticStyle:{"white-space":"pre-wrap"}},[_vm._v(_vm._s(_vm.$t('views.question.resultsNotVisibleDuringEventActive')))])])]):_vm._e(),(
        _vm.question.isFinished() ||
        (_vm.$mystore.state.event !== null &&
          _vm.$mystore.state.event.results_in_progress === true &&
          _vm.hasVoted === true &&
          Object.values(_vm.votes).length > 0)
      )?_c('div',[(Object.keys(_vm.votes).length > 0)?_c('p',[_c('span',{staticStyle:{"white-space":"pre-wrap"}},[_c('b',[_vm._v(_vm._s(_vm.$t('views.question.theseAreEventResults')))])])]):_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t('views.question.noResults')))])]),_vm._l((_vm.question.answers),function(answer){return _c('div',{key:answer.id},[_c('p',{staticClass:"break-all"},[_vm._v(" "+_vm._s(answer.label)+" "),(_vm.givenVotes.filter(function (vote) { return vote === answer.id; }).length > 0)?_c('b',[_vm._v("("+_vm._s(_vm.$t('views.question.yourVote'))+")")]):_vm._e()]),_c('v-progress-linear',{attrs:{"value":_vm.votes[answer.id] !== undefined ? _vm.votes[answer.id].percentage : 0,"height":"25"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var value = ref.value;
return [(_vm.votes[answer.id] !== undefined)?_c('strong',[_vm._v("("+_vm._s(_vm.votes[answer.id].votes)+") "+_vm._s(value)+"%")]):_vm._e(),(_vm.votes[answer.id] == undefined)?_c('strong',[_vm._v("(0) 0%")]):_vm._e()]}}],null,true)}),_c('br')],1)}),(_vm.question.abstention === true)?_c('div',[_c('p',[_vm._v(" "+_vm._s(_vm.$t('views.question.abstentioned'))+" "),(_vm.givenVotes.filter(function (vote) { return vote === null; }).length > 0)?_c('b',[_vm._v("("+_vm._s(_vm.$t('views.question.yourVote'))+")")]):_vm._e()]),_c('v-progress-linear',{attrs:{"value":_vm.votes['abstention'] !== undefined
              ? _vm.votes['abstention'].percentage
              : 0,"height":"25"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var value = ref.value;
return [(_vm.votes['abstention'] !== undefined)?_c('strong',[_vm._v("("+_vm._s(_vm.votes['abstention'].votes)+") "+_vm._s(value)+"%")]):_vm._e(),(_vm.votes['abstention'] == undefined)?_c('strong',[_vm._v("(0) 0%")]):_vm._e()]}}],null,false,3700307010)}),_c('br')],1):_vm._e()],2):(_vm.question.isActive() && _vm.hasVoted === 'pending')?_c('p',[_vm._v(" "+_vm._s(_vm.$t('views.question.thankYouForVoting'))+" ")]):_vm._e(),(_vm.hasVoted === false && _vm.question.isActive())?_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[(_vm.question.multiple_choices_enabled === false)?_c('v-radio-group',{attrs:{"disabled":_vm.abstention,"rules":_vm.selected_answer_rules},model:{value:(_vm.selected_answer),callback:function ($$v) {_vm.selected_answer=$$v},expression:"selected_answer"}},_vm._l((_vm.answers),function(answer){return _c('v-radio',{key:answer.id,attrs:{"label":answer.label,"value":answer.id}})}),1):_vm._l((_vm.answers),function(answer){return _c('v-checkbox',{key:answer.id,attrs:{"label":answer.label,"value":answer.id,"disabled":_vm.abstention,"rules":_vm.selected_answer_rules},model:{value:(_vm.selected_answer),callback:function ($$v) {_vm.selected_answer=$$v},expression:"selected_answer"}})}),(_vm.question.abstention === true)?_c('v-checkbox',{attrs:{"label":_vm.$t('views.question.abstention'),"value":true},on:{"change":_vm.toggleAbstention},model:{value:(_vm.abstention),callback:function ($$v) {_vm.abstention=$$v},expression:"abstention"}}):_vm._e(),(
          _vm.question.anonymous == true ||
          (_vm.question.anonymous == null &&
            _vm.$mystore.state.event.anonymous == true)
        )?_c('div',{staticStyle:{"white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(_vm.$t('components.layouts.main.anonymous'))+" ")]):_c('div',{staticStyle:{"white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(_vm.$t('components.layouts.main.transparent'))+" ")]),_c('v-btn',{staticClass:"mr-4 mt-5",attrs:{"disabled":!_vm.valid,"color":"success"},on:{"click":function($event){$event.stopPropagation();return _vm.formSubmit($event)}}},[_vm._v(" "+_vm._s(_vm.$t('views.question.send'))+" ")])],2):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }